<template>
	<div class="container">
		<div class="user-nav">
			<Search :isClass="false" :isSearch="false" :isBtn="false" @handleChange='handleChange' :termlist='termlist'
				ref="search" />
		</div>
		<div class="main">
			<div class="table-box">
				<h4>身心健康</h4>
				<!-- <table border="1" cellspacing="0" rules="all">
          <tr>
            <th>项目</th>
            <th>写实记录</th>
            <th>体质水平</th>
						<th>体质技能</th>
            <th>体育出勤率</th>
            <th>体育比赛</th>
            <th>心理测评</th>
            <th>健康知识测评</th>
            <th>卫生习惯</th>
            <th>总计</th>
          </tr>
          <tr>
            <td>数据条数</td>
            <td>{{healthRecord.realisticRecordNum_1}}</td>
            <td>{{healthRecord.institutionalLevelNum_2}}</td>
						<td>{{ healthRecord.sportSkillsNum }}</td>
            <td>{{healthRecord.sportsAttendanceNum_3}}</td>
            <td>{{healthRecord.sportsMatcheNum_4}}</td>
            <td>{{healthRecord.psychologicalNum_5}}</td>
            <td>{{healthRecord.healthNum_6}}</td>
            <td>{{healthRecord.hygieneNum_7}}</td>
            <td>{{healthRecord.totalNum}}</td>
          </tr>
          <tr>
            <td>综素成绩（分）</td>
            <td>{{healthScore.realisticRecordScore_1}}</td>
            <td>{{healthScore.institutionalLevelScore_2}}</td>
						<td>{{ healthScore.sportSkillsScore }}</td>
            <td>{{healthScore.sportsAttendanceScore_3}}</td>
            <td>{{healthScore.sportsMatcheScore_4}}</td>
            <td>{{healthScore.psychologicalScore_5}}</td>
            <td>{{healthScore.healthScore_6}}</td>
            <td>{{healthScore.hygieneScore_7}}</td>
            <td>{{healthScore.totalScore}}</td>
          </tr>
        </table> -->
				<table border="1" cellspacing="0" rules="all" id="total-table1">
					<tr>
						<th>分项</th>
						<th>体质水平</th>
						<th>健康知识与技能</th>
						<th>卫生习惯</th>
						<th>体育出勤率</th>
						<th>体育活动</th>
						<th>体育技能</th>
					</tr>
					<tr>
						<td>数据条数</td>
						<td>{{healthTotal.typeNum323}}</td>
						<td>{{healthTotal.typeNum324}}</td>
						<td>{{healthTotal.typeNum325}}</td>
						<td>{{healthTotal.typeNum326}}</td>
						<td>{{healthTotal.typeNum327}}</td>
						<td>{{healthTotal.typeNum328}}</td>
					</tr>
					<tr>
						<td>综素成绩（分）</td>
						<td>{{healthTotal.typeScore323}}</td>
						<td>{{healthTotal.typeScore324}}</td>
						<td>{{healthTotal.typeScore325}}</td>
						<td>{{healthTotal.typeScore326}}</td>
						<td>{{healthTotal.typeScore327}}</td>
						<td>{{healthTotal.typeScore328}}</td>
					</tr>
				</table>
				<table border="1" cellspacing="0" rules="all" id="total-table2">
					<tr>
						<th>分项</th>
						<th>体育比赛</th>
						<th>心理测评</th>
						<th>心理课程</th>
						<th>主题教育活动</th>
						<th>总计</th>
					</tr>
					<tr>
						<td>数据条数</td>
						<td>{{healthTotal.typeNum329}}</td>
						<td>{{healthTotal.typeNum330}}</td>
						<td>{{healthTotal.typeNum331}}</td>
						<td>{{healthTotal.typeNum332}}</td>
						<td>{{healthTotal.typeSumCount}}</td>
					</tr>
					<tr>
						<td>综素成绩（分）</td>
						<td>{{healthTotal.typeScore329}}</td>
						<td>{{healthTotal.typeScore330}}</td>
						<td>{{healthTotal.typeScore331}}</td>
						<td>{{healthTotal.typeScore332}}</td>
						<td>{{healthTotal.typeSumScore}}</td>
					</tr>
				</table>
			</div>
			<div class="more">
				<el-row type="flex" justify="space-between">
					<el-col :span="12">
						<h4>相关记录</h4>
					</el-col>
					<el-col :span="12" style="text-align: right">
						<a :href="pdfUrl" target="_blank">
							<el-button type="text">国家学生体质健康标准</el-button>
						</a>
					</el-col>
				</el-row>
				<el-row type="flex" justify="space-between" class="more-tit">
					<el-col :span="12">
						<h5>中学生体质水平检测</h5>
					</el-col>
					<el-col :span="12" style="text-align: right">
						<div class="more-flex">
							<!-- <span>总成绩：{{physiqueUser.totalGradesScore}}</span> -->
							<span>综素成绩：{{physiqueUser.totalIndexScore}}</span>
						</div>
					</el-col>
				</el-row>
				<div class="table-box">
					<table border="1" cellspacing="0" rules="all">
						<tr>
							<th style="width: 5%">项目</th>
							<th>检测项</th>
							<th>检测结果</th>
							<th>得分</th>
							<th>等级</th>
						</tr>
						<tr>
							<td>1</td>
							<td>体重</td>
							<td>{{physiqueUser.studentWeight}}</td>
							<td>——</td>
							<td>——</td>
						</tr>
						<tr>
							<td>2</td>
							<td>身高</td>
							<td>{{physiqueUser.studentHeight}}</td>
							<td>——</td>
							<td>——</td>
						</tr>
						<tr>
							<td>3</td>
							<td>体重指数(BMI)(kg/m2)</td>
							<td>{{physiqueUser.type1GradesScore}}</td>
							<td>{{physiqueUser.type1IndexScore}}</td>
							<td>{{physiqueUser.type1Level}}</td>
						</tr>
						<tr>
							<td>4</td>
							<td>肺活量(毫升)</td>
							<td>{{physiqueUser.type2GradesScore}}</td>
							<td>{{physiqueUser.type2IndexScore}}</td>
							<td>{{physiqueUser.type2Level}}</td>
						</tr>
						<tr>
							<td>5</td>
							<td>50米跑(秒)</td>
							<td>{{physiqueUser.type3GradesScore}}</td>
							<td>{{physiqueUser.type3IndexScore}}</td>
							<td>{{physiqueUser.type3Level}}</td>
						</tr>
						<tr>
							<td>6</td>
							<td>坐立体前驱（厘米）</td>
							<td>{{physiqueUser.type4GradesScore}}</td>
							<td>{{physiqueUser.type4IndexScore}}</td>
							<td>{{physiqueUser.type4Level}}</td>
						</tr>
						<tr>
							<td>7</td>
							<td>立定跳远（厘米）</td>
							<td>{{physiqueUser.type5GradesScore}}</td>
							<td>{{physiqueUser.type5IndexScore}}</td>
							<td>{{physiqueUser.type5Level}}</td>
						</tr>
						<tr>
							<td>8</td>
							<td>引体向上(男)/一分钟仰卧起做(女)</td>
							<td>{{physiqueUser.type6GradesScore}}</td>
							<td>{{physiqueUser.type6IndexScore}}</td>
							<td>{{physiqueUser.type6Level}}</td>
						</tr>
						<tr>
							<td>9</td>
							<td>1000米(男)/800(女)(分秒)</td>
							<td>{{physiqueUser.type7GradesScore}}</td>
							<td>{{physiqueUser.type7IndexScore}}</td>
							<td>{{physiqueUser.type7Level}}</td>
						</tr>
					</table>
				</div>

				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>体育比赛</h5>
					</el-col>
					<el-col :span="12" style="text-align: right" v-if="curRole == 'student'">
						<el-button @click="uploadMyPhy" type="primary" plain><i class="el-icon-circle-plus"></i>上传体育比赛</el-button>
					</el-col>
				</el-row>
				<el-table :data="racelist" style="width: 100%" border :header-cell-style="tableHeaderColor"
					:cell-style="cellStyleFun">
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column prop="matchName" label="赛事名称"> </el-table-column>
					<el-table-column prop="indexRuleLevel" label="赛事级别"></el-table-column>
					<el-table-column prop="matchTime" label="比赛时间"> </el-table-column>
					<el-table-column prop="_auditStatus" label="审核状态" width="120">
					</el-table-column>
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<el-button @click="handleDetailCompetition(scope.row)" type="text" size="small">查看</el-button>
						</template>
					</el-table-column>
				</el-table>

				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>体育出勤率</h5>
					</el-col>
					<el-col :span="12"></el-col>
				</el-row>
				<el-table :data="sportAttend" style="width: 100%" border :header-cell-style="tableHeaderColor"
					:cell-style="cellStyleFun">
					<el-table-column prop="name" label="评级项目">
						<template>体育出勤率</template>
					</el-table-column>
					<el-table-column prop="termName" label="学期"> </el-table-column>
					<el-table-column label="评价方式">
						<template>客观记录</template>
					</el-table-column>
					<el-table-column prop="sportRatio" label="体育课出勤率"> </el-table-column>
					<el-table-column prop="middleRatio" label="课间操出勤率"> </el-table-column>
					<el-table-column prop="sportAttendScore" label="综素得分"> </el-table-column>
					<el-table-column label="操作" fixed="right" width="120">
						<template slot-scope="scope">
							<el-button @click="handleDetailAttend(scope.row)" type="text" size="small">查看详情</el-button>
						</template>
					</el-table-column>
				</el-table>

				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>学生测评</h5>
					</el-col>
					<el-col :span="12"></el-col>
				</el-row>
				<el-table :data="testlist" style="width: 100%" border :header-cell-style="tableHeaderColor"
					:cell-style="cellStyleFun">
					<el-table-column prop="name" label="测评名称"> </el-table-column>
					<el-table-column prop="evaTypeId" label="测评类别">
						<template slot-scope="scope">
							<span v-if="scope.row.evaTypeId == 400">心理测评</span>
							<span v-if="scope.row.evaTypeId == 401">健康知识测评</span>
						</template>
					</el-table-column>
					<el-table-column prop="term" label="学期"> </el-table-column>
					<el-table-column prop="indexScore" label="状态">
						<template slot-scope="scope">
							{{scope.row.indexScore == '暂无' ? '未测评' : '已测评' }}
						</template>
					</el-table-column>
					<el-table-column prop="indexScore" label="综素得分"> </el-table-column>
					<el-table-column label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="handleEvaluate(scope.row)" type="text" size="small"
								v-if="scope.row.indexScore == '暂无' && roleKey == 'student' ">去测评</el-button>
							<el-button @click="handleEvaluateDetail(scope.row)" type="text" size="small" v-else>查看详情</el-button>
						</template>
					</el-table-column>
				</el-table>

				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>学生体育技能成绩</h5>
					</el-col>
					<el-col :span="12" style="text-align: right">

					</el-col>
				</el-row>
				<div class="table-box sport-skill-table">
					<table border="1" cellspacing="0" rules="all">
						<tr>
							<th>项目1</th>
							<th>项目1成绩</th>
							<th>项目2</th>
							<th>项目2成绩</th>
							<th>项目3</th>
							<th>项目3成绩</th>
							<th>项目4</th>
							<th>项目4成绩</th>
						</tr>
						<tr v-if="skillProjectScore">
							<td>{{ skillProjectScore.projectOne }}</td>
							<td>{{ skillProjectScore.projectOneScore }}</td>
							<td>{{ skillProjectScore.projectTwo }}</td>
							<td>{{ skillProjectScore.projectTwoScore }}</td>
							<td>{{ skillProjectScore.projectThree }}</td>
							<td>{{ skillProjectScore.projectThreeScore }}</td>
							<td>{{ skillProjectScore.projectFour }}</td>
							<td>{{ skillProjectScore.projectFourScore }}</td>
						</tr>
						<tr v-else>
							<td colspan="8">暂无数据</td>
						</tr>
					</table>
				</div>

				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>卫生习惯不良记录</h5>
					</el-col>
					<el-col :span="12" style="text-align: right">

					</el-col>
				</el-row>
				<el-table :data="breakListDtos" style="width: 100%" border :header-cell-style="tableHeaderColor"
					:cell-style="cellStyleFun">
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column prop="breakContext" label="记录内容"> </el-table-column>
					<el-table-column prop="breakScore" label="扣分值"> </el-table-column>
					<el-table-column prop="createTime" label="发布时间"> </el-table-column>
					<el-table-column prop="breakStatus" label="状态">
						<template slot-scope="scope">
							{{scope.row.breakStatus == '0' ? '存在' : '撤销'}}
						</template>
					</el-table-column>
					<el-table-column label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="handleDetailHygienic(scope.row)" type="text" size="small">查看</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<!-- 查看详情 -->
		<el-dialog :title="title" :visible.sync="open" width="600px" center>
			<el-form :model="form" style="
            border-top: 1px solid #e1e3e9;
            border-bottom: 1px solid #e1e3e9;
            padding-top: 5px;
          ">
				<el-form-item style="border-bottom: 1px dashed #e1e3e9">
					<span class="sname">{{ form.userName }}</span>
					<span class="snumb">学籍号：{{ form.studentCode }}</span>
				</el-form-item>
				<!-- 卫生 -->
				<template v-if="!isRace">
					<el-form-item label="记录内容：">
						<span class="scontent">{{ form.breakContext }}</span>
					</el-form-item>
					<el-form-item label="扣分值：">
						<span class="scontent">{{ form.breakScore }}</span>
					</el-form-item>
					<el-form-item label="发布时间：">
						<span class="scontent">{{ form.createTime }}</span>
					</el-form-item>
					<el-form-item label="状态：" style="border-bottom: 1px dashed #e1e3e9">
						<span style="color: red">{{ form.breakStatus }}</span>
					</el-form-item>
				</template>
				<!-- 体育 -->
				<template v-if="isRace">
					<el-form-item label="比赛名称：">
						<span class="scontent">{{ form.matchName }}</span>
					</el-form-item>
					<el-form-item label="比赛级别：">
						<span class="scontent">{{ form.indexRuleLevel }}</span>
					</el-form-item>
					<el-form-item label="获奖等级：">
						<span class="scontent">{{ form.resultLevel }}</span>
					</el-form-item>
					<el-form-item label="比赛时间：">
						<span class="scontent">{{ form.matchTime }}</span>
					</el-form-item>
					<el-form-item label="举办单位：" style="border-bottom: 1px dashed #e1e3e9">
						<span class="scontent">{{ form.awardedUnit }}</span>
					</el-form-item>
				</template>
				<el-form-item label="所在学期：">
					<span class="scontent">{{ form.termName }}</span>
				</el-form-item>
				<!-- <el-form-item label="所在学校：">
            <span class="scontent">{{ form.schoolName }}</span>
          </el-form-item> -->
				<el-form-item label="佐证材料：" v-if="form._showImgList">
					<el-image v-for="(item, index) in form._imgFileList" style="width: 100px; height: 100px;"
						:preview-src-list="form._imgFileList" :key="index" :src="item"></el-image>
				</el-form-item>
				<el-form-item label="佐证材料：" v-if="form._showOtherFileList">
					<a v-for="(item, idx) in form._otherFileList" :key="item" target="_blank" :download="'附件' + idx"
						:href="item">附件{{ idx + 1 }} </a>
				</el-form-item>
				<el-form-item label="备注说明：">
					<span class="scontent">{{ form.bz }}</span>
				</el-form-item>
				<template v-if="!isRace && form.breakStatus == '撤销'">
					<el-form-item label="撤销原因：">
						<span class="scontent">{{ form.revokeCause }}</span>
					</el-form-item>
					<el-form-item label="撤销备注说明：">
						<span class="scontent">{{ form.revokeRemark }}</span>
					</el-form-item>
				</template>
				<template v-if="isRace && form.auditStatus == 2">
					<el-form-item label="撤销原因：">
						<span class="scontent">{{ form.causeDes }}</span>
					</el-form-item>
				</template>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="open = false">关 闭</el-button>
				<el-button type="primary" @click="reupload"
					v-if="form.auditStatus == 0 || form.auditStatus == 2">重新编辑上传</el-button>
			</span>
		</el-dialog>

		<el-dialog title="体育出勤率" center width="850px" :visible.sync="openDetail" :close-on-click-modal="false"
			:destroy-on-close="true">
			<el-form :model="form" ref="form">
				<el-form-item label="班级：">
					<span class="scontent">{{ form.className }}</span>
				</el-form-item>
				<el-form-item label="学期：">
					<span class="scontent">{{ termName }}</span>
				</el-form-item>
				<el-form-item label="学生姓名：">
					<span class="scontent">{{ form.userName }}</span>
				</el-form-item>
				<el-form-item label="学籍号：">
					<span class="scontent">{{ form.userNum }}</span>
				</el-form-item>
				<el-form-item label="学生考勤：">
					<el-row><el-col></el-col></el-row>
					<el-row :gutter="10" type="flex" justify="end">
						<el-col :span="7"></el-col>
						<el-col :span="17" style="text-align: right">
							<el-select v-model="detailParams.attendanceType" @change="getListDetail" placeholder="出勤类型" size="mini"
								style="width: 150px;margin-right:10px">
								<el-option label="体育课出勤" value="1"></el-option>
								<el-option label="课间操出勤" value="2"></el-option>
							</el-select>
							<el-select v-model="detailParams.attendanceStatus" @change="getListDetail" placeholder="出勤状态" size="mini"
								style="width: 150px;margin-right:10px" clearable>
								<el-option label="缺勤" value="0"></el-option>
								<el-option label="出勤" value="1"></el-option>
							</el-select>
							<el-select v-show="false" v-model="form.attendanceStatus" placeholder="缺勤原因" size="mini"
								style="width: 150px;margin-right:10px" clearable>
								<el-option label="缺勤" value="0"></el-option>
								<el-option label="出勤" value="1"></el-option>
							</el-select>
							<!-- <el-button
                type="primary"
                size="mini"
                style="margin-left: 10px"
                @click="getListDetail"
                >搜索</el-button
              > -->
						</el-col>
					</el-row>
					<template v-if="detailParams.attendanceType == 1">
						<el-table style="width: 100%" border :header-cell-style="tableHeaderColor" :cell-style="cellStyleFun"
							:data="form.attendanceStudents">
							<el-table-column type="index" label="序号" width="50"></el-table-column>
							<el-table-column property="shangkeTime" label="上课时间" width="180"></el-table-column>
							<el-table-column property="shangkeTeacher" label="上课老师" min-width="150"></el-table-column>
							<el-table-column property="attendanceStatus" label="出勤状态" width="130">
								<template slot-scope="scope">
									{{ scope.row.attendanceStatus == '0' ? '缺勤' : '出勤' }}
								</template>
							</el-table-column>
							<el-table-column property="unattendanceCause" label="缺勤原因" width="150"></el-table-column>
						</el-table>
					</template>

					<template v-if="detailParams.attendanceType == 2">
						<el-table style="width: 100%" border :header-cell-style="tableHeaderColor" :cell-style="cellStyleFun"
							:data="form.attendanceStudents">
							<el-table-column type="index" label="序号" width="50"></el-table-column>
							<el-table-column property="shangkeTime" label="课间操时间" width="180"></el-table-column>
							<el-table-column property="shangkeTeacher" label="课间操时段" min-width="150"></el-table-column>
							<el-table-column property="attendanceStatus" label="出勤状态" width="130">
								<template slot-scope="scope">
									{{ scope.row.attendanceStatus == '0' ? '缺勤' : '出勤' }}
								</template>
							</el-table-column>
							<el-table-column property="unattendanceCause" label="缺勤原因" width="150"></el-table-column>
						</el-table>
					</template>

					<pagination v-show="totalDetail > 0" :total="totalDetail" :page.sync="detailParams.pageNum"
						:limit.sync="detailParams.pageSize" @pagination="getListDetail" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="openDetail = false">关 闭</el-button>
			</div>
		</el-dialog>
		<!-- 个人体育比赛 -->
		<el-dialog title="上传体育比赛" :visible.sync="dialog.addMyPhy" width="600px" center>
			<el-form :model="addMyPhyForm" :rules="addMyPhyFormRules" ref="addMyPhyForm">
				<el-form-item label="学生姓名：" prop="userName">
					<span>{{ addMyPhyForm.userName }}</span>
				</el-form-item>
				<el-form-item label="学籍号：" prop="studentCode">
					<span>{{ addMyPhyForm.studentCode }}</span>
				</el-form-item>
				<el-form-item label="比赛名称：" prop="matchName">
					<el-input style="width: 300px;" placeholder="请输入比赛名称" v-model="addMyPhyForm.matchName"></el-input>
				</el-form-item>
				<el-form-item label="比赛级别：" prop="indexRuleLevel">
					<el-select style="width: 300px;" v-model="addMyPhyForm.indexRuleLevel" placeholder="请选择比赛级别">
						<el-option v-for="(item, index) in constData.acLvlList" :key="index" :label="item.name"
							:value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="获奖等级：" prop="resultLevel">
					<el-input style="width: 300px;" placeholder="请输入获奖等级" v-model="addMyPhyForm.resultLevel"></el-input>
				</el-form-item>
				<el-form-item label="比赛时间：" prop="matchTime">
					<el-date-picker v-model="addMyPhyForm.matchTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
						placeholder="请选择比赛时间" style="width: 300px;" :picker-options="ltToday">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="举办单位：" prop="awardedUnit">
					<el-input style="width: 300px;" placeholder="请输入举办单位" v-model="addMyPhyForm.awardedUnit"></el-input>
				</el-form-item>
				<el-form-item label="所属学期：">
					<span>{{ addMyPhyForm.termName }}</span>
				</el-form-item>
				<el-form-item label="佐证材料：" prop="file">
					<el-upload :action="upload.action" :accept="upload.accept" :limit="upload.limit" :multiple="upload.multiple"
						:file-list="upload.list" :on-change="uploadChange" :on-remove="uploadRemove" :auto-upload="upload.auto">
						<el-button size="small" type="primary" v-if="upload.list.length < 3">添加附件</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="备注说明：" prop="bz">
					<el-input style="width: 300px;" type="textarea" v-model="addMyPhyForm.bz" placeholder="情况说明,选填"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeDialog('addMyPhy')">取 消</el-button>
				<el-button type="primary" @click="confirmUploadMyPhy">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Search from "@/components/Search";
	import {
		getMorality,
		getCompetionDetail,
		getQuestionNaire,
		submitTest,
		getTestDetail,
		getWejiDetail,
		getAttendanceDetail
	} from '@/api/student/myquality/shenxin'
	import {
		addCompetion
	} from '@/api/teacher/quality/health'
	import {
		getDic
	} from "@/api/public/search"
	export default {
		data() {
			return {
				pdfUrl: `${process.env.VUE_APP_API}/shida-user-biz/static/国家学生体质健康标准.pdf`,
				imgSrc: this.imageSrc(),
				curRole: this.$store.getters.roles[0].roleKey,
				termName: "",
				healthRecord: {},
				healthScore: {},
				sportAttend: [],
				breakListDtos: [],
				physiqueUser: {},
				skillProjectScore: {},
				healthTotal: {},
				tableData: [],
				racelist: [],
				testlist: [],
				termlist: [],
				open: false,
				openAdd: false,
				openNaire: false,
				openDetail: false,
				title: "",
				isRace: false,
				form: {
					questionNaire: [],
				},
				roleKey: '',
				totalDetail: 0,
				detailParams: {
					pageNum: 1,
					pageSize: 15,
					attendanceType: '1',
					attendanceStatus: '',
				},
				addMyPhyForm: {
					userName: this.$store.getters.name,
					studentCode: this.$store.getters.loginName,
					termName: '',
					matchName: '',
					schoolName: '',
					matchTime: '',
					indexRuleLevel: '',
					resultLevel: '',
					id: '',
					awardedUnit: '',
					bz: ''
				},
				upload: {
					action: '',
					accept: '.jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt',
					limit: 3,
					multiple: false,
					list: [],
					auto: false
				},
				constData: {
					phyLvlList: []
				},
				dialog: {
					addMyPhy: false
				},
				addMyPhyFormRules: {
					matchName: [{
						required: true,
						message: "请输入比赛名称",
						trigger: "blur"
					}, ],
					indexRuleLevel: [{
						required: true,
						message: "请选择比赛级别",
						trigger: "change"
					}, ],
					matchTime: [{
						required: true,
						message: "请选择比赛时间",
						trigger: "blur"
					}],
					awardedUnit: [{
						required: true,
						message: "请输入举办单位",
						trigger: "blur"
					}, ]
				}
			}
		},
		components: {
			Search
		},
		created() {
			if (this.$store.getters.roles.length > 0) {
				this.roleKey = this.$store.getters.roles[0].roleKey
			}
			this.$store.dispatch('GetTermList').then(res => {
				this.termlist = res.data
				this.termName = this.$store.getters.queryForm.termName
				this.addMyPhyForm.termName = this.$store.getters.queryForm.termName;
				this.$refs.search.init(this.termName)
				this.getList()
				this.getPhyLvlDic()

			})
		},
		methods: {
			// 审核状态
			aStatus(st) {
				return ['未审核', '审核通过', '审核驳回'][st]
			},
			// 比赛级别字典
			getPhyLvlDic() {
				getDic({
					type: "matchLevel"
				}).then(res => {
					this.constData.acLvlList = res.data
				})
			},
			// 打开上传个人体育比赛
			uploadMyPhy() {
				this.addMyPhyForm.matchName = ''
				this.addMyPhyForm.matchTime = ''
				this.addMyPhyForm.indexRuleLevel = ''
				this.addMyPhyForm.awardedUnit = ''
				this.addMyPhyForm.bz = ''
				this.addMyPhyForm.id = ''
				this.upload.list = []
				this.openDialog('addMyPhy')
			},
			// 打开弹窗
			openDialog(nm) {
				this.dialog[nm] = true
			},
			// 关闭弹窗
			closeDialog(nm) {
				this.dialog[nm] = false
			},
			// 确认上传个人体育比赛
			confirmUploadMyPhy() {
				this.$refs.addMyPhyForm.validate(vld => {
					if (vld) {
						let fd = new FormData()
						fd.append('userName', this.addMyPhyForm.userName)
						fd.append('studentCode', this.addMyPhyForm.studentCode)
						fd.append('matchName', this.addMyPhyForm.matchName)
						fd.append('matchTime', this.addMyPhyForm.matchTime)
						fd.append('indexRuleLevel', this.addMyPhyForm.indexRuleLevel)
						fd.append('awardedUnit', this.addMyPhyForm.awardedUnit)
						fd.append('resultLevel', this.addMyPhyForm.resultLevel)
						if (this.upload.list.length > 0) {
							this.upload.list.forEach(v => {
								fd.append('file', v)
							})
						}
						if (this.addMyPhyForm.id) {
							fd.append('id', this.addMyPhyForm.id)
						}
						fd.append('bz', this.addMyPhyForm.bz)
						addCompetion(fd).then(res => {
							this.$message.success(res.message)
							this.closeDialog('addMyPhy')
							this.getList()
						})
					} else {
						return false
					}
				})
			},
			// 材料上传
			uploadChange(file, fileList) {
				let rawTp = file.raw.type
				// pdf, excel表格, word文档, 图, 纯文本
				let allowTp = ['application/pdf', 'application/vnd.ms-excel',
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'image/gif',
					'image/png', 'image/jpeg', 'text/plain'
				]
				if (allowTp.indexOf(rawTp) == -1) {
					this.$message.error("材料仅支持pdf、excel表格、word、图片与纯文本格式！")
					fileList.pop()
				} else {
					this.upload.list.push(file.raw)
					this.$message.success("上传成功")
				}
			},
			// 材料删除
			uploadRemove(file, fileList) {
				this.upload.list = fileList
			},
			// 审核驳回重新编辑上传
			reupload() {
				this.open = false
				this.addMyPhyForm = Object.assign(this.addMyPhyForm, this.form)
				// 找到级别
				let lvl = ''
				this.constData.acLvlList.forEach(v => {
					if (v.name == this.form.indexRuleLevel) {
						lvl = v.code
					}
				})
				this.addMyPhyForm.indexRuleLevel = `${lvl}`
				this.upload.list = []
				this.openDialog('addMyPhy')
			},
			// 材料路径不是图片
			fileNotImg(url) {
				return [/\.xls$/, /\.xlsx$/, /\.doc$/, /\.docx$/, /\.pdf$/, /\.txt$/].some(v => v.test(url))
			},
			// 处理附件
			processAth(key) {
				let temp = key.split(",")
				let imgFileList = []
				let otherFileList = []
				// 材料按文件后缀名分流
				temp.forEach(v => {
					if (this.fileNotImg(v)) {
						otherFileList.push(this.imgSrc + v)
					} else {
						imgFileList.push(this.imgSrc + v)
					}
				})
				this.form._imgFileList = imgFileList
				this.form._otherFileList = otherFileList
				this.form._showImgList = imgFileList.length > 0
				this.form._showOtherFileList = otherFileList.length > 0
			},
			getList() {
				this.sportAttend = []
				getMorality({
					term: this.termName
				}).then(res => {
					// console.log(res);
					let d = res.data
					d.matchList.forEach(v => {
						v._auditStatus = this.aStatus(v.auditStatus)
					})
					this.racelist = d.matchList
					this.testlist = d.evaluationDtoList
					this.healthRecord = d.healthRecordNum
					this.healthScore = d.healthIndexScore
					this.sportAttend.push(d.sportattendDto)
					this.breakListDtos = d.breakListDtos
					this.physiqueUser = d.physiqueUser
					this.skillProjectScore = d.sportSkillsProjectUserScore
					// 新的总计字段key为typeXXX
					let keyReg = /^type[a-zA-Z]+/
					for(let i in d) {
						if (keyReg.test(i)) {
							this.healthTotal[i] = d[i]
						}
					}
				})
			},
			handleChange(val) {
				this.termName = val
				this.addMyPhyForm.termName = val
				this.getList()
			},
			handleDetailCompetition(row) {
				this.title = "体育比赛";
				this.open = true;
				this.isRace = true;
				getCompetionDetail({
					matchId: row.id
				}).then((res) => {
					// console.log(res);
					this.form = res.data;
					this.addMyPhyForm.id = row.id
					if (this.form.proofFile) {
						this.processAth(this.form.proofFile)
					}
					this.open = true;
				});
			},
			handleDetailAttend(row) {
				this.openDetail = true
				this.form.className = this.$store.getters.dept.deptName
				this.form.userName = this.$store.getters.name
				this.form.userNum = this.$store.getters.loginName
				this.detailParams = {
					termName: this.termName,
					attendanceType: '1',
				}
				this.getListDetail()
			},
			getListDetail() {
				getAttendanceDetail(this.detailParams).then(res => {
					this.form.attendanceStudents = res.data.list
					this.totalDetail = res.data.total
					if (this.detailParams.attendanceType == '2') {
						this.form.attendanceStudents.forEach(item => {
							item.shangkeTeacher = parseInt(item.shangkeTime.slice(11, 13)) <= 12 ? '上午' : '下午'
						})
					}
				})
			},
			handleEvaluate(row) {
				this.$router.push({
					name: 'test',
					params: row
				})
				sessionStorage['params'] = JSON.stringify(row)
			},
			handleEvaluateDetail(row) {
				row.userId = this.$store.getters.userId
				this.$router.push({
					name: 'detail',
					params: row
				})
				sessionStorage['params'] = JSON.stringify(row)
			},
			handleDetailHygienic(row) {
				this.title = "卫生习惯不良记录";
				this.open = true;
				this.isRace = false;
				getWejiDetail({
					breakId: row.id
				}).then((res) => {
					// console.log(res);
					this.form = res.data;
					if (this.form.proofFile) {
						this.processAth(this.form.proofFile)
					}
					this.open = true;
				});
			},

			tableHeaderColor({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (rowIndex === 0) {
					return "background-color: #F6F7FA;color: #91939A;font-weight: blod;text-align:center";
				}
			},
			cellStyleFun() {
				return "text-align:center";
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/studentResult.scss";

	.container {
		.user-nav {
			padding: 10px 12px;
		}
		.main {
			.table-box {
      table {
        tr {
          th {
            width: 10%;
          }
        }
      }
    }
		
			.more {
				.table-box {
					table {
						tr {
							th {
								width: 23%;
							}
						}
					}
				}
			}
		}
		.sport-skill-table {
			table {
				th {
					width: 12.5% !important;
				}
			}
		}
		#total-table1 {
			margin-bottom: 15px;
			tr {
				width: 14.2857%;
			}
		}
		#total-table2 {
			tr {
				width: 16.6667%;
			}
		}
	}
</style>
<style scoped>
	.user-nav .el-select>>>.el-input {
		margin: 10px 12px;
	}

	.el-form-item {
		margin-bottom: 12px;
	}

	.el-form-item>>>.el-form-item__label {
		font-weight: bold;
		color: #303133;
	}

	.el-image {
		width: 300px;
	}
</style>